@tailwind base;
@tailwind components;
@tailwind utilities;


.bodyLarge {
  font-family: var(--Text-body-large-fontFamily);
  font-size: var(--Text-body-large-fontSize);
  font-weight: var(--Text-body-large-fontWeight);
  letter-spacing: var(--Text-body-large-letterSpacing);
  line-height: var(--Text-body-large-lineHeight);
  text-decoration: var(--Text-body-large-textDecoration);
  text-transform: var(--Text-body-large-textTransform);
}

.bodyMedium {
  font-family: var(--Text-body-medium-fontFamily);
  font-size: var(--Text-body-medium-fontSize);
  font-weight: var(--Text-body-medium-fontWeight);
  letter-spacing: var(--Text-body-medium-letterSpacing);
  line-height: var(--Text-body-medium-lineHeight);
  text-decoration: var(--Text-body-medium-textDecoration);
  text-transform: var(--Text-body-medium-textTransform);
}

.bodySmall {
  font-family: var(--Text-body-small-fontFamily);
  font-size: var(--Text-body-small-fontSize);
  font-weight: var(--Text-body-small-fontWeight);
  letter-spacing: var(--Text-body-small-letterSpacing);
  line-height: var(--Text-body-small-lineHeight);
  text-decoration: var(--Text-body-small-textDecoration);
  text-transform: var(--Text-body-small-textTransform);
}

.displayLarge {
  font-family: var(--Text-display-large-fontFamily);
  font-size: var(--Text-display-large-fontSize);
  font-weight: var(--Text-display-large-fontWeight);
  letter-spacing: var(--Text-display-large-letterSpacing);
  line-height: var(--Text-display-large-lineHeight);
  text-decoration: var(--Text-display-large-textDecoration);
  text-transform: var(--Text-display-large-textTransform);
}

.displayMedium {
  font-family: var(--Text-display-medium-fontFamily);
  font-size: var(--Text-display-medium-fontSize);
  font-weight: var(--Text-display-medium-fontWeight);
  letter-spacing: var(--Text-display-medium-letterSpacing);
  line-height: var(--Text-display-medium-lineHeight);
  text-decoration: var(--Text-display-medium-textDecoration);
  text-transform: var(--Text-display-medium-textTransform);
}

.displaySmall {
  font-family: var(--Text-display-small-fontFamily);
  font-size: var(--Text-display-small-fontSize);
  font-weight: var(--Text-display-small-fontWeight);
  letter-spacing: var(--Text-display-small-letterSpacing);
  line-height: var(--Text-display-small-lineHeight);
  text-decoration: var(--Text-display-small-textDecoration);
  text-transform: var(--Text-display-small-textTransform);
}

.displayXsmall {
  font-family: var(--Text-display-xsmall-fontFamily);
  font-size: var(--Text-display-xsmall-fontSize);
  font-weight: var(--Text-display-xsmall-fontWeight);
  letter-spacing: var(--Text-display-xsmall-letterSpacing);
  line-height: var(--Text-display-xsmall-lineHeight);
  text-decoration: var(--Text-display-xsmall-textDecoration);
  text-transform: var(--Text-display-xsmall-textTransform);
}

.headlineLarge {
  font-family: var(--Text-headline-large-fontFamily);
  font-size: var(--Text-headline-large-fontSize);
  font-weight: var(--Text-headline-large-fontWeight);
  letter-spacing: var(--Text-headline-large-letterSpacing);
  line-height: var(--Text-headline-large-lineHeight);
  text-decoration: var(--Text-headline-large-textDecoration);
  text-transform: var(--Text-headline-large-textTransform);
}

.headlineMedium {
  font-family: var(--Text-headline-medium-fontFamily);
  font-size: var(--Text-headline-medium-fontSize);
  font-weight: var(--Text-headline-medium-fontWeight);
  letter-spacing: var(--Text-headline-medium-letterSpacing);
  line-height: var(--Text-headline-medium-lineHeight);
  text-decoration: var(--Text-headline-medium-textDecoration);
  text-transform: var(--Text-headline-medium-textTransform);
}

.headlineSmall {
  font-family: var(--Text-headline-small-fontFamily);
  font-size: var(--Text-headline-small-fontSize);
  font-weight: var(--Text-headline-small-fontWeight);
  letter-spacing: var(--Text-headline-small-letterSpacing);
  line-height: var(--Text-headline-small-lineHeight);
  text-decoration: var(--Text-headline-small-textDecoration);
  text-transform: var(--Text-headline-small-textTransform);
}

.labelLarge {
  font-family: var(--Text-label-large-fontFamily);
  font-size: var(--Text-label-large-fontSize);
  font-weight: var(--Text-label-large-fontWeight);
  letter-spacing: var(--Text-label-large-letterSpacing);
  line-height: var(--Text-label-large-lineHeight);
  text-decoration: var(--Text-label-large-textDecoration);
  text-transform: var(--Text-label-large-textTransform);
}

.labelMedium {
  font-family: var(--Text-label-medium-fontFamily);
  font-size: var(--Text-label-medium-fontSize);
  font-weight: var(--Text-label-medium-fontWeight);
  letter-spacing: var(--Text-label-medium-letterSpacing);
  line-height: var(--Text-label-medium-lineHeight);
  text-decoration: var(--Text-label-medium-textDecoration);
  text-transform: var(--Text-label-medium-textTransform);
}

.labelSmall {
  font-family: var(--Text-label-small-fontFamily);
  font-size: var(--Text-label-small-fontSize);
  font-weight: var(--Text-label-small-fontWeight);
  letter-spacing: var(--Text-label-small-letterSpacing);
  line-height: var(--Text-label-small-lineHeight);
  text-decoration: var(--Text-label-small-textDecoration);
  text-transform: var(--Text-label-small-textTransform);
}

.titleLarge {
  font-family: var(--Text-title-large-fontFamily);
  font-size: var(--Text-title-large-fontSize);
  font-weight: var(--Text-title-large-fontWeight);
  letter-spacing: var(--Text-title-large-letterSpacing);
  line-height: var(--Text-title-large-lineHeight);
  text-decoration: var(--Text-title-large-textDecoration);
  text-transform: var(--Text-title-large-textTransform);
}

.titleMedium {
  font-family: var(--Text-title-medium-fontFamily);
  font-size: var(--Text-title-medium-fontSize);
  font-weight: var(--Text-title-medium-fontWeight);
  letter-spacing: var(--Text-title-medium-letterSpacing);
  line-height: var(--Text-title-medium-lineHeight);
  text-decoration: var(--Text-title-medium-textDecoration);
  text-transform: var(--Text-title-medium-textTransform);
}

.titleSmall {
  font-family: var(--Text-title-small-fontFamily);
  font-size: var(--Text-title-small-fontSize);
  font-weight: var(--Text-title-small-fontWeight);
  letter-spacing: var(--Text-title-small-letterSpacing);
  line-height: var(--Text-title-small-lineHeight);
  text-decoration: var(--Text-title-small-textDecoration);
  text-transform: var(--Text-title-small-textTransform);
}

.titleTitle {
  font-family: var(--Text-title-title-fontFamily);
  font-size: var(--Text-title-title-fontSize);
  font-weight: var(--Text-title-title-fontWeight);
  letter-spacing: var(--Text-title-title-letterSpacing);
  line-height: var(--Text-title-title-lineHeight);
  text-decoration: var(--Text-title-title-textDecoration);
  text-transform: var(--Text-title-title-textTransform);
}

.titleXlarge {
  font-family: var(--Text-title-xlarge-fontFamily);
  font-size: var(--Text-title-xlarge-fontSize);
  font-weight: var(--Text-title-xlarge-fontWeight);
  letter-spacing: var(--Text-title-xlarge-letterSpacing);
  line-height: var(--Text-title-xlarge-lineHeight);
  text-decoration: var(--Text-title-xlarge-textDecoration);
  text-transform: var(--Text-title-xlarge-textTransform);
}

