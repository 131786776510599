@import "../abstracts/mixins";

*,
*::before,
*::after {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

html {
   --Padding-Section-Global: var(--Padding-Section, 24px);
   --Padding-Section-Top-Global: 72px;

   @include respond(phone) {
      --Padding-Section-Global: 72px;
      --Padding-Section-Top-Global: 70px;
   }

   @include respond(tab-port) {
      --Padding-Section-Global: 50px;
      --Padding-Section-Top-Global: 70px;
   }

   @include respond(tab-land) {
      --Padding-Section-Global: 55px;
      --Padding-Section-Top-Global: 70px;
   }

   @include respond(laptop) {
      --Padding-Section-Global: 100px;
      --Padding-Section-Top-Global: 80px;
   }
   @include respond(desktop) {
      --Padding-Section-Global: 100px;
      --Padding-Section-Top-Global: 180px;
   }
}

body {
   min-height: 100dvh;
   background: #fff !important;
}

#wrapper {
   background: #fff !important;
}

.p-component {
   font-family: var(--Fonts-FontName, Tajawal), sans-serif !important;
}

.shadow-md {
   box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -2px rgba(0, 0, 0, 0.05);
}

html {
   overflow-x: hidden;
   scroll-behavior: smooth;
}

.line {
   width: 100%;
   height: 1px;
   background: var(--Schemes-Outline-Variant, #c4c6d1);
}

.Toastify {
   &__toast {
      @include isAr() {
         direction: rtl;
      }
   }
}

.scrollbar {
   @include scrollbar();
}

a {
   color: inherit;
   text-decoration: inherit;

   &:hover {
      color: inherit;
      text-decoration: inherit;
   }
}

.row {
   display: flex;
}

.hamburger-menu {
   cursor: pointer;
   transition: all 0.1s ease;
}

.line {
   transition: all 0.1s ease;
}

.hamburger-menu.open .line.top {
   transform: translateY(5px) rotate(45deg);
   transform-origin: center;
}

.hamburger-menu.open .line.middle {
   opacity: 0;
}

.hamburger-menu.open .line.bottom {
   transform: translateY(-5px) rotate(-45deg);
   transform-origin: center;
}

#template-content,
.home-page {
   background: #fff;
}

a {
   &:hover {
       color: #CCCCCC;
   }
}