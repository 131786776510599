.btn {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	border-radius: 8px;
	transition: all 0.3s cubic-bezier(0.45, 0.01, 0, 0.99);
	border: 1px solid transparent;
	outline-color: transparent;

	&-primary {
		color: var(--Schemes-On-Primary, #FFFFFF);
		background: var(--Schemes-Primary, #2C37E1);
		justify-content: flex-end;
		font-family: Roboto;
		font-size: 19px;
		font-weight: 700;
		line-height: 22.27px;
		height: 69px;

		&:not(:disabled):hover {
			background: var(--Schemes-Primary-Light, #4567e3)
		}

		&:not(:disabled):active,
		&:not(:disabled):focus {
			outline: 3px solid #e5e5e4;
			border-color: transparent;
			background: var(--Schemes-Primary-Dark, #1a2a4e);
		}
	}


	&-outline {
		color: var(--Schemes-On-Primary-Container, #978c6b);
		border-color: var(--Schemes-Outline-Variant, #e5e5e4);
		background: transparent;

		&:not(:disabled):hover {
			color: var(--Schemes-On-Primary, #2d2b28);
		}
	}

	&-rounded {
		border-radius: 999rem;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.8;
	}

	&-xl {
		padding: 0.875rem 1.5rem;

		&.squared {
			padding: 0.875rem;
		}
	}

	&-l {
		padding: 0.75rem 1.25rem;

		&.squared {
			padding: 0.75rem;
		}
	}

	&-base {
		padding: 0.625rem 1.5rem;

		&.squared {
			padding: 0.625rem;
		}
	}

	&-sm {
		padding: 0.5rem 0.75rem;

		&.squared {
			padding: 0.5rem;
		}
	}

	.spinner {
		position: absolute;
		inset: 0;
		transform: scale(0.8);

		svg {
			height: 100%;
		}
	}

	&.hide-siblings {
		color: transparent !important;

		>*:not(.spinner) {
			color: transparent !important;
		}
	}
}