@mixin respond($breakpoint, $min: 0, $max: 100vw) {
   @if $breakpoint==custom-media {
      @media (min-width: $min) and (max-width: $max) {
         @content;
      }
   }

   @if $breakpoint==phone {
      @media (min-width: 425px) {
         @content;
      }
   }

   @if $breakpoint==tab-port {
      @media (min-width: 768px) {
         @content;
      }
   }

   @if $breakpoint==tab-land {
      @media (min-width: 1024px) {
         @content;
      }
   }

   @if $breakpoint==laptop {
      @media (min-width: 1400px) {
         @content;
      }
   }

   @if $breakpoint==desktop {
      @media (min-width: 1800px) {
         @content;
      }
   }
}

@mixin isAr() {
   &:dir(rtl) {
      @content;
   }
}

@mixin isEn() {
   &:dir(ltr) {
      @content;
   }
}

@mixin scrollbar() {
   &::-webkit-scrollbar {
      width: 8px;
   }

   &::-webkit-scrollbar-track {
      background: transparent;
   }

   &::-webkit-scrollbar-thumb {
      background-color: var(--Schemes-Primary);
      border-radius: 10px;
   }
}

@mixin limitLines($lines) {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: $lines;
   line-clamp: $lines;
   -webkit-box-orient: vertical;
}

@mixin heightMatchingLineHeight($fontSize, $linesCount, $lineHeightDecimal: 1) {
   height: calc(#{$fontSize} * $lineHeightDecimal *#{$linesCount});
}
