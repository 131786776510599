@import "../../assets/scss/abstracts/mixins";

.news-letter {
    height: fit-content;
    min-height: 146.49px;
    background: #EEEEEE;
    box-shadow: 3px 23px 64px 0px #0000000A;

    margin-top: var(--Padding-Section-Top-Global);
    padding-inline: var(--Padding-Section-Global);


    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding-block: 20px;

    @include respond(tab-port) {
        flex-direction: row;
        align-items: center;
        padding-block: 0px;
        height: 120px;
        min-height: 120px;

    }

    @include respond(laptop) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 60px;

    }

    &--text {
        font-family: Roboto;
        font-size: clamp(1.25rem, 4vw, 40px);
        font-weight: 500;
        letter-spacing: -0.005em;

        @include respond(tab-land) {
            font-size: 30px;
        }
        @include respond(laptop) {
            font-size: clamp(1.25rem, 4vw, 40px);
        }
    }
}