.button-mailto,
.button-telto,
.button-mapto {
    display: flex;
    align-items: center;
    gap: 15px;

    img {
        width: 38px;
        height: 38px;
    }
}