@import "../../assets/scss/abstracts/mixins";

.subscribe-input {
    height: 56px;
    width: 100%;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    position: relative;

    @include respond(tab-land) {
        width: 500px;
    }

    @include respond(laptop) {
        width: 520px;
    }

    @include respond(desktop) {
        width: 520px;
    }

    &--input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100% !important;
        border-radius: 48px;
        border: 0;
        padding-inline: 32px;
        padding-right: 186px;

        transition: box-shadow 0.25s ease-in-out;

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        &:focus:not(&-error),
        &:focus-visible:not(&-error) {
            outline: 1px solid rgb(84 105 212 / 0.5);
        }

        @include respond(laptop) {
            width: 520px !important;
        }

        &-error {
            outline: 2px solid var(--Schemes-Danger);
        }
    }

    &--button {
        height: 100% !important;
        border: 0;
        display: flex;
        align-items: center;
        width: 176px;

        padding: 0px 32px 0px 32px;
        gap: 12px;
        border-radius: 48px !important;
        background: #2C37E1;
        position: absolute !important;
        top: 0;
        bottom: 0;
        right: 0;

        span {
            font-family: Roboto;
            font-size: 17px;
            font-weight: 500;
            line-height: 56px;
            color: #FFFFFF;
        }
        outline: none !important;
        border: 0 !important;
    }
}