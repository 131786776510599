@import "../../assets/scss/main.scss";

:root {
    --mainColor: #030029;
    --mainColorLight: #5767aa;
    --secondaryColor: #db2b39;
    --textColor: #eee;
}

.header {
    height: 113px;
    display: flex;
    align-items: center;
    background: #030029;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-high, 2030);
    overflow: hidden;
    transition: height 0.3s ease-in-out;

    &--logo {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-inline: var(--Padding-Section-Global);

        span {
            font-size: 24px;
            font-weight: 500;
            line-height: 94px;
            color: #fff;
            visibility: hidden;

            @include respond(phone) {
                visibility: visible;
            }
        }
    }

    &--nav-menu {
        display: flex;
        align-items: center;
        gap: 30px;

        @include respond(laptop) {
            gap: 73px;
        }

        &--item {
            color: #FFFFFF;
            position: relative;
            transition: color 0.2s ease-in-out;
            width: fit-content;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 400;
            line-height: 18px;


            &.active {
                color: #1709FA;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    width: 50%;
                    height: 3.4px;
                    border-radius: 20px;
                    background-color: #1709FA;
                    animation: menu 0.5s forwards;

                }
            }
        }



        a {
            &:hover {
                color: #1709FA;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    width: 50%;
                    height: 3.4px;
                    border-radius: 20px;
                    background-color: #1709FA;

                    animation: menu 0.5s forwards;

                }
            }

            // &:not(.active):not(:hover) {
            //     &::after {
            //         content: "";
            //         display: block;
            //         position: absolute;
            //         bottom: -8px;
            //         left: 0;
            //         width: 50%;
            //         height: 3.4px;
            //         border-radius: 20px;
            //         background-color: #1709FA;

            //         animation: menuReverse 0.5s forwards;
            //     }
            // }
        }
    }

    .nav-btn {
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        color: var(--textColor);
        visibility: hidden;
        opacity: 0;

        font-size: 1.8rem;

        &:not(.nav-close-btn) {
            padding: var(--Padding-Section, 24px);
            margin-left: auto;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .header {
        height: 60px;
    }

    .header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    .header nav {
        position: fixed;
        top: -200vh;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
    }

    .header .isOpen {
        transform: translateY(200vh);
        transition: transform 0.5s ease-in-out;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    nav a {
        font-size: 1.5rem;
    }
}

@keyframes menu {
    from {
        width: 0;
    }

    to {
        width: 50%;
    }
}

@keyframes menuReverse {
    from {
        width: 50%;
    }

    to {
        width: 0;
    }
}