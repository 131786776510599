.contact-info-input {

    display: flex;
    flex-direction: column;

    gap: 10px;

    a {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #FFFFFFB2;
    }

}