.footer--logos--social-media {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    svg {
        &:hover {
            cursor: pointer;
        }
    }
}