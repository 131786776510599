@import "../../assets/scss/main.scss";

.footer {
    min-height: 558.19px;

    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    flex-grow: 1;
    place-items: center;

    padding-inline: var(--Padding-Section-Global);

    background: #030029;
    overflow: hidden;
    position: relative;
    padding-block: 40px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -174px;
        left: -205px;
        width: 203px;
        height: 300px;
        background-color: rgba(210, 93, 255, 0.6980392157);
        filter: blur(100px);
        z-index: var(--z-index-low, 2000);
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -13px;
        right: -130px;
        width: 203px;
        height: 300px;
        background-color: rgba(210, 93, 255, 0.6980392157);
        filter: blur(100px);
        z-index: var(--z-index-low, 2000);
    }

    @include respond(tab-land) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include respond(laptop) {
        padding-block: 0;
        gap: 16px;
    }


    &--logos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 28px;
        width: 100%;

        &--logo {
            display: flex;
            flex-direction: column;
            gap: 25px;

            span {
                font-family: Roboto;
                font-size: 50px;
                font-weight: 500;
                line-height: 94px;
                color: #FFFFFF;
            }
        }

        &--social-media {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
    }

    &--quick-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;
        width: 100%;

        &--header {
            font-family: Roboto;
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            color: #FFFFFF;
        }

        &--list {
            display: flex;
            flex-direction: column;

            gap: 10px;

            a {
                font-family: Roboto;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                color: #FFFFFFB2;
            }
        }
    }

    &--contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 18px;
        width: 100%;

        &--header {
            font-family: Roboto;
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            color: #FFFFFF;
        }

    }

    .a-links {}
}