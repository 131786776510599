@import "./mixins";

@font-face {
   font-family: "Roboto";
   src: url("../../font/Roboto/Roboto-Black.ttf") format("truetype");
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   src: url("../../font/Roboto/Roboto-Bold.ttf") format("truetype");
   font-weight: 700;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   src: url("../../font/Roboto/Roboto-Medium.ttf") format("truetype");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   src: url("../../font/Roboto/Roboto-Regular.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   src: url("../../font/Roboto/Roboto-Light.ttf") format("truetype");
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: "Roboto";
   src: url("../../font/Roboto/Roboto-Thin.ttf") format("truetype");
   font-weight: 200;
   font-style: normal;
}


:root {
   --Padding-Header-Section: 100px;
   --Padding-Section-Top: 180px;
   --Padding-Section-lg: 100px;
   --Padding-Section-md: 100px;
   --Padding-Section-sm: 100px;
   --Padding-Section-xl: 100px;
   --Padding-Section-xs: 10px;
   --Padding-Section: 24px;
   --Fs-xxl: clamp(2rem, 8vw, 70px);
   --Fs-xl: clamp(1.75rem, 7vw, 60px);
   --Fs-l: clamp(1.5rem, 5vw, 48px);
   --Fs-medium: clamp(1.7rem, 5vw, 27.21px);
   --Fs-small: clamp(1rem, 3vw, 18px);

   --Border-radius-rounded-2xl: 16px;
   --Border-radius-rounded-3xl: 24px;
   --Border-radius-rounded-full: 9999px;
   --Border-radius-rounded-lg: 8px;
   --Border-radius-rounded-md: 6px;
   --Border-radius-rounded-sm: 2px;
   --Border-radius-rounded-xl: 12px;
   --Border-radius-rounded: 4px;
   --Border-radius-Sharp: 0px;
   --Container-2xl: 1536px;
   --Container-3xl: 1920px;
   --Container-lg: 1024px;
   --Container-md: 768px;
   --Container-sm: 640px;
   --Container-xl: 1280px;
   --Max-width-max-w-2xl: 672px;
   --Max-width-max-w-3xl: 768px;
   --Max-width-max-w-4xl: 896px;
   --Max-width-max-w-5xl: 1024px;
   --Max-width-max-w-6xl: 1152px;
   --Max-width-max-w-7xl: 1280px;
   --Max-width-max-w-lg: 512px;
   --Max-width-max-w-md: 448px;
   --Max-width-max-w-sm: 384px;
   --Max-width-max-w-xl: 576px;
   --Max-width-max-w-xs: 320px;
   --Schemes-Background: #f9f8f5;
   --Schemes-Danger-Container: #f77979;
   --Schemes-Danger: #bd1313;
   --Schemes-Emphithes-Container: #ffebcc;
   --Schemes-Emphithes-Variant-Container: #9bb0d9;
   --Schemes-Emphithes-Variant: #1c64f2;
   --Schemes-Emphithes: #c27803;
   --Schemes-Error-Container: #ffdad6;
   --Schemes-Inverse-On-Surface: #faefe2;
   --Schemes-Inverse-Primary: #edc06c;
   --Schemes-Inverse-Surface: #ada076;
   --Schemes-Link: #1a56db;
   --Schemes-On-Background-light: #767676;
   --Schemes-On-Background: #3e3a35;
   --Schemes-On-Danger-Container: #630000;
   --Schemes-On-Danger: #ffe0e0;
   --Schemes-On-Emphithes-Container: #683302;
   --Schemes-On-Emphithes-Variant-Container: #002f8c;
   --Schemes-On-Emphithes-Variant: #ffffff;
   --Schemes-On-Emphithes: #fff8f1;
   --Schemes-On-Error-Container: #3b0907;
   --Schemes-On-Error: #ffffff;
   --Schemes-On-Primary-Container: #978c6b;
   --Schemes-On-Primary-Fixed-Variant: #5e4200;
   --Schemes-On-Primary-Fixed: #271900;
   --Schemes-On-Primary: #FFFFFF;
   --Schemes-On-Secondary-Container: #3f465a;
   --Schemes-On-Secondary-Fixed-Variant: #264777;
   --Schemes-On-Secondary-Fixed: #001b3d;
   --Schemes-On-Secondary: #ffffff;
   --Schemes-On-Success-Container: #1c6802;
   --Schemes-On-Success: #f3faf7;
   --Schemes-On-Surface-Variant: #374151;
   --Schemes-On-Surface: #c0c4cf;
   --Schemes-On-Tertiary-Container: #574f35;
   --Schemes-On-Tertiary-Fixed-Variant: #544600;
   --Schemes-On-Tertiary-Fixed: #221b00;
   --Schemes-On-Tertiary: #332f29;
   --Schemes-Outline-Variant-light: #f5f5f5;
   --Schemes-Outline-Variant: #e5e5e4;
   --Schemes-Outline: #ccc5bc;
   --Schemes-Overlay-dark: #2e2e2e0f;
   --Schemes-Overlay-darker: #201b1329;
   --Schemes-Overlay-darkest: #201b1352;
   --Schemes-Overlay-light: #ffffff59;
   --Schemes-Primary-Container: #faf9f5;
   --Schemes-Primary-Fixed-Dim: #edc06c;
   --Schemes-Primary-Fixed: #ffdea6;
   --Schemes-Primary: #2C37E1;
   --Schemes-Primary-Light: #4567e3;
   --Schemes-Primary-Dark: #1a2a4e;
   --Schemes-Scrim: #000000;
   --Schemes-Secondary-Container: #d7ddf3;
   --Schemes-Secondary-Fixed-Dim: #a9c7ff;
   --Schemes-Secondary-Fixed: #d6e3ff;
   --Schemes-Secondary: #6b7697;
   --Schemes-Shadow: #000000;
   --Schemes-Success-Container: #bcf0c1;
   --Schemes-Success: #31920f;
   --Schemes-Surface-Bright: #faf8f5;
   --Schemes-Surface-Container-High: #e0e0e0;
   --Schemes-Surface-Container-Higher: #d6d6d6;
   --Schemes-Surface-Container-Highest: #cccccc;
   --Schemes-Surface-Container-Low: #f2f2f2;
   --Schemes-Surface-Container-Lower: #fafafa;
   --Schemes-Surface-Container-Lowest: #ffffff;
   --Schemes-Surface-Container: #ebebeb;
   --Schemes-Surface-Dim-Secoundary: #737c84;
   --Schemes-Surface-Dim: #f5eee6;
   --Schemes-Surface-Tint: #e0d4af;
   --Schemes-Surface-Variant: #d1d5db;
   --Schemes-Surface: #f9f8f5;
   --Schemes-Tertiary-Container: #f7f3eb;
   --Schemes-Tertiary-Fixed-Dim: #c8a003;
   --Schemes-Tertiary-Fixed: #fff79c;
   --Schemes-Tertiary: #e2d9cb;
   --Spacing-spacing-0: 0px;
   --Spacing-spacing-1: 1px;
   --Spacing-spacing-10: 10px;
   --Spacing-spacing-100: 100px;
   --Spacing-spacing-104: 104px;
   --Spacing-spacing-108: 108px;
   --Spacing-spacing-112: 112px;
   --Spacing-spacing-116: 116px;
   --Spacing-spacing-12: 12px;
   --Spacing-spacing-120: 120px;
   --Spacing-spacing-124: 124px;
   --Spacing-spacing-128: 128px;
   --Spacing-spacing-14: 14px;
   --Spacing-spacing-16: 16px;
   --Spacing-spacing-2: 2px;
   --Spacing-spacing-20: 20px;
   --Spacing-spacing-24: 24px;
   --Spacing-spacing-28: 28px;
   --Spacing-spacing-32: 32px;
   --Spacing-spacing-36: 36px;
   --Spacing-spacing-4: 4px;
   --Spacing-spacing-40: 40px;
   --Spacing-spacing-44: 44px;
   --Spacing-spacing-48: 48px;
   --Spacing-spacing-52: 52px;
   --Spacing-spacing-56: 56px;
   --Spacing-spacing-6: 6px;
   --Spacing-spacing-60: 60px;
   --Spacing-spacing-64: 64px;
   --Spacing-spacing-69: 68px;
   --Spacing-spacing-72: 72px;
   --Spacing-spacing-76: 76px;
   --Spacing-spacing-8: 8px;
   --Spacing-spacing-80: 80px;
   --Spacing-spacing-84: 84px;
   --Spacing-spacing-88: 88px;
   --Spacing-spacing-92: 92px;
   --Spacing-spacing-96: 96px;
   --Text-body-large-fontFamily: Roboto, sans-serif;
   --Text-body-large-fontSize: 16px;
   --Text-body-large-fontWeight: 400;
   --Text-body-large-letterSpacing: 0px;
   --Text-body-large-lineHeight: 150%;
   --Text-body-large-textDecoration: none;
   --Text-body-large-textTransform: none;
   --Text-body-medium-fontFamily: Roboto, sans-serif;
   --Text-body-medium-fontSize: 14px;
   --Text-body-medium-fontWeight: 500;
   --Text-body-medium-letterSpacing: 0px;
   --Text-body-medium-lineHeight: 150%;
   --Text-body-medium-textDecoration: none;
   --Text-body-medium-textTransform: none;
   --Text-body-small-fontFamily: Roboto, sans-serif;
   --Text-body-small-fontSize: 12px;
   --Text-body-small-fontWeight: 500;
   --Text-body-small-letterSpacing: 0px;
   --Text-body-small-lineHeight: 24px;
   --Text-body-small-textDecoration: none;
   --Text-body-small-textTransform: none;
   --Text-display-large-fontFamily: Roboto, sans-serif;
   --Text-display-large-fontSize: 56px;
   --Text-display-large-fontWeight: 400;
   --Text-display-large-letterSpacing: 0px;
   --Text-display-large-lineHeight: 64px;
   --Text-display-large-textDecoration: none;
   --Text-display-large-textTransform: none;
   --Text-display-medium-fontFamily: Roboto, sans-serif;
   --Text-display-medium-fontSize: 44px;
   --Text-display-medium-fontWeight: 400;
   --Text-display-medium-letterSpacing: 0px;
   --Text-display-medium-lineHeight: 52px;
   --Text-display-medium-textDecoration: none;
   --Text-display-medium-textTransform: none;
   --Text-display-small-fontFamily: Roboto, sans-serif;
   --Text-display-small-fontSize: 40px;
   --Text-display-small-fontWeight: 400;
   --Text-display-small-letterSpacing: 0px;
   --Text-display-small-lineHeight: 46px;
   --Text-display-small-textDecoration: none;
   --Text-display-small-textTransform: none;
   --Text-display-xsmall-fontFamily: Roboto, sans-serif;
   --Text-display-xsmall-fontSize: 36px;
   --Text-display-xsmall-fontWeight: 500;
   --Text-display-xsmall-letterSpacing: 0px;
   --Text-display-xsmall-lineHeight: 44px;
   --Text-display-xsmall-textDecoration: none;
   --Text-display-xsmall-textTransform: none;
   --Text-headline-large-fontFamily: Roboto, sans-serif;
   --Text-headline-large-fontSize: 32px;
   --Text-headline-large-fontWeight: 400;
   --Text-headline-large-letterSpacing: 0px;
   --Text-headline-large-lineHeight: 150%;
   --Text-headline-large-textDecoration: none;
   --Text-headline-large-textTransform: none;
   --Text-headline-medium-fontFamily: Roboto, sans-serif;
   --Text-headline-medium-fontSize: 28px;
   --Text-headline-medium-fontWeight: 700;
   --Text-headline-medium-letterSpacing: 0px;
   --Text-headline-medium-lineHeight: 36px;
   --Text-headline-medium-textDecoration: none;
   --Text-headline-medium-textTransform: none;
   --Text-headline-small-fontFamily: Roboto, sans-serif;
   --Text-headline-small-fontSize: 24px;
   --Text-headline-small-fontWeight: 700;
   --Text-headline-small-letterSpacing: 0px;
   --Text-headline-small-lineHeight: 140%;
   --Text-headline-small-textDecoration: none;
   --Text-headline-small-textTransform: none;
   --Text-label-large-fontFamily: Roboto, sans-serif;
   --Text-label-large-fontSize: 14px;
   --Text-label-large-fontWeight: 500;
   --Text-label-large-letterSpacing: 0px;
   --Text-label-large-lineHeight: 130%;
   --Text-label-large-textDecoration: none;
   --Text-label-large-textTransform: none;
   --Text-label-medium-fontFamily: Roboto, sans-serif;
   --Text-label-medium-fontSize: 12px;
   --Text-label-medium-fontWeight: 700;
   --Text-label-medium-letterSpacing: 0px;
   --Text-label-medium-lineHeight: 16px;
   --Text-label-medium-textDecoration: none;
   --Text-label-medium-textTransform: none;
   --Text-label-small-fontFamily: Roboto, sans-serif;
   --Text-label-small-fontSize: 11px;
   --Text-label-small-fontWeight: 700;
   --Text-label-small-letterSpacing: 0px;
   --Text-label-small-lineHeight: 16px;
   --Text-label-small-textDecoration: none;
   --Text-label-small-textTransform: none;
   --Text-title-large-fontFamily: Roboto, sans-serif;
   --Text-title-large-fontSize: 22px;
   --Text-title-large-fontWeight: 500;
   --Text-title-large-letterSpacing: 0px;
   --Text-title-large-lineHeight: 32px;
   --Text-title-large-textDecoration: none;
   --Text-title-large-textTransform: none;
   --Text-title-medium-fontFamily: Roboto, sans-serif;
   --Text-title-medium-fontSize: 16px;
   --Text-title-medium-fontWeight: 500;
   --Text-title-medium-letterSpacing: 0px;
   --Text-title-medium-lineHeight: 150%;
   --Text-title-medium-textDecoration: none;
   --Text-title-medium-textTransform: none;
   --Text-title-small-fontFamily: Roboto, sans-serif;
   --Text-title-small-fontSize: 14px;
   --Text-title-small-fontWeight: 700;
   --Text-title-small-letterSpacing: 0px;
   --Text-title-small-lineHeight: 150%;
   --Text-title-small-textDecoration: none;
   --Text-title-small-textTransform: none;
   --Text-title-title-fontFamily: Roboto, sans-serif;
   --Text-title-title-fontSize: 18px;
   --Text-title-title-fontWeight: 400;
   --Text-title-title-letterSpacing: 0px;
   --Text-title-title-lineHeight: 150%;
   --Text-title-title-textDecoration: none;
   --Text-title-title-textTransform: none;
   --Text-title-xlarge-fontFamily: Roboto, sans-serif;
   --Text-title-xlarge-fontSize: 24px;
   --Text-title-xlarge-fontWeight: 400;
   --Text-title-xlarge-letterSpacing: 0px;
   --Text-title-xlarge-lineHeight: 32px;
   --Text-title-xlarge-textDecoration: none;
   --Text-title-xlarge-textTransform: none;

   --z-index-high: 2030;
   --z-index-medium: 2020;
   --z-index-low: 2000;
}

@media (max-width: 768px) {
   :root {
      --Fs-xxl: clamp(1.5rem, 6vw, 50px);
      --Fs-xl: clamp(1.25rem, 5vw, 40px);
      --Fs-l: clamp(1rem, 4vw, 30px);
   }
}